import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid } from "@mui/material"
import ScrollToTheElement from "../../../Helper/Operations/ScrollToTheElement"
import { getBatteriesHealth, getBatteryHealthDistribution } from "../../../Api/BatteryHealthApi"
import { SetSessionExpired } from "../../../Actions"
import CODE from "../../../Static/Constants/StatusCodes"
import { getMarkerColorForSOHChart, getMinXForScatterChart, getSOHLevels } from "../../../Helper/BatteryHealth/BatteryHealthHelper"
import BasicTableFleetBatteryHealth from "../../../Features/Table/AntDesign/BasicTableFleetBatteryHealth"
import SOHDoughnutChartSection from "../SOHDoughnutChartSection"
import SOHScatterChartSection from "../SOHScatterChartSection"

function LandingOverview(props) {
  const dispatch = useDispatch()
  const [batteriesData, setBatteriesData] = useState({
    data: [],
    scatterChartData: [],
    batteriesIds: [],
    xMin: 0,
    yMin: 60,
    xMax: 0,
    xMax: 0,
    responseStatus: { code: -1 }
  })
  const [healthDistributionData, setHealthDistributionData] = useState({
    series: [],
    labels: [],
    sohLevels: {},
    totalLabel: "Total Batteries",
    responseStatus: { code: -1 },
  })
  const cycleAge = useSelector((state) => state.BatteryHealthCycleAge.value)
  const sohLevel = useSelector((state) => state.BatteryHealthSOH.value)
  const eqCycle = useSelector((state) => state.BatteryHealthEqCycle.value);
  const columns = useSelector((state) => state.BatteryHealthColumns.value);
  const selectedFilters = useSelector(
    (state) => state.SelectedFilterValues.value
  )
  const sort = {
    sortBy: useSelector((state) => state.TableSortColumn.value),
    order: useSelector((state) => state.TableSortOrder.value)
  }

  useEffect(() => {
    ScrollToTheElement()
    fetchBatteriesHealthData()
    fetchHealthDistributionData()
  }, [selectedFilters, sort.sortBy, sort.order])

  useEffect(() => {
    ScrollToTheElement()
    fetchBatteriesHealthData()
  }, [sohLevel, cycleAge])

  const generateScatterChartData = (data) => {
    let scatterChartData = []
    let batteriesIds = []
    let xMax = 0;

    data?.forEach((item) => {
      if (item.age === null || item.sohEst === null) {
        return
      }
      if (cycleAge && item.eqCycleEst === null) {
        return
      }
      
      const age = item.age !== null ? item.age / 30 : 0
      scatterChartData.push({
        x: cycleAge ? item.eqCycleEst : age,
        y: item.sohEst,
        batteryID: item.batteryID,
        deviceID: item.deviceID,
        secondaryX: age,
        fillColor:getMarkerColorForSOHChart(item.sohEst)
      });
      batteriesIds.push(item.batteryID);

      const maxAgeOrCycle = cycleAge ? Math.ceil(item.eqCycleEst) : Math.ceil(age)

      if (maxAgeOrCycle > xMax) {
        xMax = maxAgeOrCycle
      }
    })

    return {scatterChartData, batteriesIds, xMax}
  }

  const fetchBatteriesHealthData = async () => {
    setBatteriesData({...batteriesData, responseStatus: {
      code: -1,
      msg: ""
    }})
    const res = await getBatteriesHealth(
      selectedFilters, sort, sohLevel
    )
    
    if (res?.responseStatus?.code === CODE.SUCCESS) {
      const chartData  = generateScatterChartData(res.response?.data)
      let yMax = sohLevel.max
      let yMin = sohLevel.min
      let xMinSohFilter = getMinXForScatterChart(eqCycle)

      setBatteriesData({
        data: res.response?.data,
        scatterChartData: chartData.scatterChartData,
        batteriesIds: chartData.batteriesIds,
        xMax: chartData.xMax,
        yMax: yMax,
        yMin: yMin,
        xMin: cycleAge ? xMinSohFilter : 0,
        responseStatus: {
          code: 200
        }
      })
      return 
    }

    if (res?.responseStatus?.code === CODE.NODATA) {
      setBatteriesData({
        ...batteriesData,
        data: [],
        scatterChartData: [],
        batteriesIds: [],
        responseStatus: {
          code: CODE.NODATA
        }
      })
    }

    if (res?.responseStatus?.code === CODE.SESSION_EXPIRED) {
      dispatch(SetSessionExpired(true))
    }
  }

  const fetchHealthDistributionData = async () => {
    setHealthDistributionData({...healthDistributionData, responseStatus: {
      code: -1,
      msg: ""
    }})
    
    const res = await getBatteryHealthDistribution(selectedFilters)
    if (res.responseStatus.code === CODE.SUCCESS) {
      const sohLevels = getSOHLevels(Object.keys(res.response?.data))
      setHealthDistributionData({
        series: Object.values(res.response?.data),
        labels: sohLevels.labels,
        sohLevels: sohLevels.data,
        totalLabel: "Total Batteries",
        responseStatus: {
          code: res.responseStatus.code,
          msg: res.responseStatus.msg,
        },
      });
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={4}>
       <SOHDoughnutChartSection 
        healthDistributionData={healthDistributionData}
        setHealthDistributionData={setHealthDistributionData}
       />
      </Grid>
      <Grid item xs={6} lg={8}>
        <SOHScatterChartSection 
          batteriesData={batteriesData}
        />
      </Grid>
      <Grid item xs={12}>
        <BasicTableFleetBatteryHealth
          key={columns.length}
          data={batteriesData}
          total={batteriesData?.data?.length}
          columns={columns?.[0]?.columns || []}
          onClickColumn={"insertionTime"}
          navigateTo={"batteryId"}
          height={740}
          pagePerSize={20}
        />
      </Grid>
    </Grid>
  )
}

export default LandingOverview
