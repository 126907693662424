export const SetFilterValues = (data = {}) => {
  return {
    type: 'selectedFilters',
    value: data,
  };
};

export const SetFilterDefaultValues = (data = {}) => {
  return {
    type: 'selectedFiltersDefaultValues',
    value: data,
  };
};

export const SetTableSortColumn = (data = {}) => {
  return {
    type: 'tableSortColumn',
    value: data,
  };
};

export const SetTableSortOrder = (data = {}) => {
  return {
    type: 'tableSortOrder',
    value: data,
  };
};

export const SetDialogTableSortColumn = (data = {}) => {
  return {
    type: 'dialogTableSortColumn',
    value: data,
  };
};

export const SetDialogTableSortOrder = (data = {}) => {
  return {
    type: 'dialogTableSortOrder',
    value: data,
  };
};