import { decryptTheParams } from "../Helper/QueryParams/EncryptDecrypt";

const initialFilterValues = {
  value: {},
};

const initialDefaultFilterValues = {
  value: null
};

const initialSortColumn = {
  value: decryptTheParams()?.sortColumn || null
}

const initialSortingOrder = {
  value: decryptTheParams()?.sortOrder || 'desc'
}

const initialDialogTableSortColumn = {
  value: decryptTheParams()?.dialogTableSortColumn || null
}

const initialDialogTableSortOrder = {
  value: decryptTheParams()?.dialogTableSortOrder || 'desc'
}

const SelectedFilterValues = (state = initialFilterValues, action) => {
  switch (action.type) {
    case "selectedFilters":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const SelectedFilterDefaultValues = (state = initialDefaultFilterValues, action) => {
  switch (action.type) {
    case "selectedFiltersDefaultValues":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const TableSortColumn = (state = initialSortColumn, action) => {
  switch (action.type) {
    case "tableSortColumn":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const TableSortOrder = (state = initialSortingOrder, action) => {
  switch (action.type) {
    case "tableSortOrder":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DialogTableSortColumn = (state = initialDialogTableSortColumn, action) => {
  switch (action.type) {
    case "dialogTableSortColumn":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DialogTableSortOrder = (state = initialDialogTableSortOrder, action) => {
  switch (action.type) {
    case "dialogTableSortOrder":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

export { 
  SelectedFilterValues,
  SelectedFilterDefaultValues,
  TableSortColumn,
  TableSortOrder,
  DialogTableSortColumn,
  DialogTableSortOrder
}