import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Table } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";
import { useNavigate } from "react-router-dom";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import CODE from "../../../Static/Constants/StatusCodes";
import { useDispatch, useSelector } from "react-redux";
import {
  updateQueryParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import {
  SetBatteryHealthPageNumber,
  SetBatteryHealthRowsPerPage,
} from "../../../Actions/BatteryHealth";
import { SetTableSortColumn, SetTableSortOrder } from "../../../Actions/Filters";
import { SOHTableNoDataFoundContainer, SOHTableNoDataFoundText } from "../../../PagesContent/BatteryHealth/Components/StyledComponent";

export default function BasicTableFleetBatteryHealth(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageNumber = useSelector((state) => state.BatteryHealthPageNumber.value);
  const rowsPerPage =  useSelector((state) => state.BatteryHealthRowsPerPage.value);
  const [columns, setColumns] = useState(props.columns);
  const [list, setList] = useState({
    batteries: [],
    responseStatus: {
      code: -1,
      msg: "",
    },
  });

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: pageNumber,
      pageSize: rowsPerPage,
      total:
        props.data.data !== undefined && props.data.data.length > 0
          ? props.data.data.length
          : 0,
          showTotal: (total,range) => `${range[0]}-${range[1]}/${total}`,
    },
  });

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newColumns = [...columns];
      const item = props.rowSelection
        ? newColumns.splice(fromIndex - 1, 1)[0]
        : newColumns.splice(fromIndex, 1)[0];
      props.rowSelection
        ? newColumns.splice(toIndex - 1, 0, item)
        : newColumns.splice(toIndex, 0, item);

      setColumns(newColumns);
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  useEffect(() => {
    setList({
      batteries: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    if (props.data.data !== undefined) {
      let newData = props.data.data.map((row) => ({
        ...row,
        tableData: {
          checked: false,
        },
      }));
      setList({
        batteries: newData,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: pageNumber !== 1 ? pageNumber : 1,
          total: props.data.data.length,
          showTotal: (total,range) => `${range[0]}-${range[1]}/${total}`,
        },
      });
    }
  }, [props.data]);

  if (!props.columns?.length) {
    return <></>
  }

  const handleTableChange = (pagination, filters, sorter, extras) => {
    if (sorter.column) {
      let order = sorter.order === 'ascend' ? 'asc' : 'desc'
      dispatch(SetTableSortColumn(sorter.column.dbColumnKey));
      dispatch(SetTableSortOrder(order));
    }
    setTableParams({
      pagination: {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        showTotal: (total,range) => `${range[0]}-${range[1]}/${total}`,
      },
    });
    dispatch(SetBatteryHealthPageNumber(pagination.current));
    dispatch(SetBatteryHealthRowsPerPage(pagination.pageSize))
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: ThemeProperties.purple,
          },
        }}
      >
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  const queryParams = {
                    batteryID: record.batteryID,
                    deviceID: record.deviceID,
                    navigateFromTab: true,
                    tabValue: 'health',
                  };
              
                  updateQueryParams(navigate, queryParams, true)
                }
              };
            }}
            locale={{
              emptyText: (
                <SOHTableNoDataFoundContainer>
                  {list.responseStatus.code === 1999 ||
                  list.responseStatus.code === 404 ||
                  list.responseStatus.code === -2 ? (
                    <SOHTableNoDataFoundText>No Batteries found</SOHTableNoDataFoundText>
                  ) : <></>}
                </SOHTableNoDataFoundContainer>
              ),
            }}
            dataSource={
              list.responseStatus.code === CODE.LOADING ||
              list.responseStatus.code === 1999 ||
              list.responseStatus.code === 404 ||
              list.responseStatus.code === -2
              ? []
              : list.batteries
            }
            columns={columns}
            rowSelection={false}
            loading={
              list.responseStatus.code === CODE.LOADING
                ? true
                : list.responseStatus.code === CODE.NODATA ||
                  list.responseStatus.code === -2
                ? false
                : false
            }
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            scroll={{ y: 700 }}
            bordered
            size="middle"
          />
        </ReactDragListView.DragColumn>
      </ConfigProvider>
    </>
  );
}
